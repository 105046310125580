import React from "react";
import {Avatar, Button, ButtonGroup, Card, Grid, IconButton, List, ListItem, Typography, useTheme} from "@mui/joy";
import {ListItemAvatar, ListItemText, useMediaQuery} from "@mui/material";
import {ProjectPortfolioItem} from "../ProjectPortfolioItem";


const gitlab_link = {
    text: "Source Code",
    short_text: undefined,
    icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-gitlab-48.png"
}

const docker_link = {
    text: "Docker Images",
    short_text: undefined,
    icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-docker-48.png"
}


export const Portfolio: React.FC = () => {
    const theme = useTheme();
    const is_big = useMediaQuery(theme.breakpoints.up('md'));

    return <>
        <Grid container justifyContent="center" id="software_portfolio">
            <Grid xs={12} md={10} xl={8}>
                <Card variant={"soft"} sx={{marginTop: 3}}>
                    <Typography level="h4" id={"portfolio"}>
                        Software Portfolio
                    </Typography>
                    <List>
                        <ProjectPortfolioItem
                            icon={"https://gitlab.alexware.systems/uploads/-/system/project/avatar/213/icons8-festplatteneinstellungen-48.png?width=48"}
                            name={"Media Library Tape Backup"}
                            description={"Backup files from ZFS volume directly to LTO Tape"}
                            links={[
                                {
                                    ...gitlab_link,
                                    href: "https://gitlab.alexware.systems/alexwaresystems/media-tape-backup",
                                }
                            ]}
                        />
                        <ProjectPortfolioItem
                            icon={"https://gitlab.alexware.systems/uploads/-/system/project/avatar/202/icons8-downloads-100.png?width=48"}
                            name={"Android OTA Server"}
                            description={"Serve Android OTA updates from S3 storage"}
                            links={[
                                {
                                    ...gitlab_link,
                                    href: "https://gitlab.alexware.systems/alexwaresystems/android/ota",
                                    text: "Source Code (API)",
                                    short_text: "API",
                                },
                                {
                                    ...gitlab_link,
                                    href: "https://gitlab.alexware.systems/alexwaresystems/android/ota-webui",
                                    text: "Source Code (WebUI)",
                                    short_text: "WebUI",
                                }
                            ]}
                        />
                        <ProjectPortfolioItem
                            icon={"https://gitlab.alexware.systems/uploads/-/system/project/avatar/127/car.png?width=48"}
                            name={"Midori Car Dashboard"}
                            description={"Custom Android Auto User Interface"}
                            links={[
                                {
                                    ...gitlab_link,
                                    href: "https://gitlab.alexware.systems/alexwaresystems/midori"
                                }
                            ]}
                        />
                        <ProjectPortfolioItem
                            icon={"https://s3.alexware.systems/icons/icons8/fluency/icons8-print-96.png"}
                            name={"Phomemo QY driver"}
                            description={"Repackaged Phomemo QY driver for rpm-based systems"}
                            links={[
                                {
                                    ...gitlab_link,
                                    href: "https://gitlab.alexware.systems/alexanderkehr/phomemo-m110-driver"
                                }
                            ]}
                        />
                        <ProjectPortfolioItem
                            icon={"https://s3.alexware.systems/icons/icons8/fluency/icons8-led-48.png"}
                            name={"Unifi LED Control"}
                            description={"Control Unifi Access Point LEDs via REST-API Endpoint"}
                            links={[
                                {
                                    ...docker_link,
                                    href: "https://gitlab.alexware.systems/alexwaresystems/unifi-led-control/container_registry/54"
                                },
                                {
                                    ...gitlab_link,
                                    href: "https://gitlab.alexware.systems/alexwaresystems/unifi-led-control"
                                }
                            ]}
                        />
                        <ProjectPortfolioItem
                            icon={"https://gitlab.alexware.systems/uploads/-/system/project/avatar/200/emby.png?width=48"}
                            name={"Emby + Mediainfo"}
                            description={"Emby Docker container with Mediainfo plugin dependencies"}
                            links={[
                                {
                                    ...docker_link,
                                    href: "https://gitlab.alexware.systems/alexwaresystems/emby-mediainfo/container_registry/55"
                                },
                                {
                                    ...gitlab_link,
                                    href: "https://gitlab.alexware.systems/alexwaresystems/emby-mediainfo"
                                }
                            ]}
                        />
                    </List>
                </Card>
            </Grid>
        </Grid>
    </>
}