import {MapContainer, Polygon, TileLayer} from "react-leaflet";
import * as React from "react";
import {LatLngExpression} from "leaflet";

const center: LatLngExpression = [49.5, 8.48]

const polygon: LatLngExpression[] = [
    [49.18, 8.09],
    [49.15, 8.13],
    [49.10, 8.16],
    [49.07, 8.18],
    [49.03, 8.30],
    [48.98, 8.32],
    [48.96, 8.40],
    [48.99, 8.47],
    [49.05, 8.53],
    [49.11, 8.61],
    [49.19, 8.70],
    [49.24, 8.74],
    [49.22, 8.90],
    [49.22, 8.92],
    [49.25, 8.92],
    [49.32, 8.83],
    [49.39, 8.80],
    [49.42, 8.72],
    [49.43, 8.69],
    [49.47, 8.66],
    [49.54, 8.67],
    [49.68, 8.64],
    [49.73, 8.61],
    [49.77, 8.61],
    [49.80, 8.66],
    [49.85, 8.71],
    [49.89, 8.71],
    [49.93, 8.69],
    [49.93, 8.60],
    [49.91, 8.55],
    [49.86, 8.54],
    [49.82, 8.54],
    [49.77, 8.50],
    [49.73, 8.45],
    [49.69, 8.36],
    [49.66, 8.34],
    [49.68, 8.28],
    [49.71, 8.24],
    [49.75, 8.16],
    [49.76, 8.13],
    [49.75, 8.10],
    [49.74, 8.07],
    [49.72, 8.09],
    [49.69, 8.12],
    [49.67, 8.16],
    [49.64, 8.19],
    [49.62, 8.18],
    [49.60, 8.17],
    [49.56, 8.14],
    [49.54, 8.08],
    [49.50, 7.98],
    [49.45, 7.80],
    [49.46, 7.75],

    // KL
    [49.46, 7.74],
    [49.45, 7.68],
    [49.43, 7.67],
    [49.42, 7.68],
    [49.42, 7.71],
    [49.42, 7.74],
    [49.42, 7.77],
    [49.43, 7.80],
    [49.44, 7.82],
    [49.45, 7.87],
    [49.49, 8.04],

    [49.52, 8.14],
    [49.51, 8.17],
    [49.50, 8.17],
    [49.49, 8.16],
    [49.46, 8.15],
    [49.45, 8.15],
    [49.44, 8.17],
    [49.44, 8.19],
    [49.38, 8.18],
    [49.37, 8.14],
    [49.36, 8.12],
    [49.35, 8.12],
    [49.32, 8.15],
    [49.28, 8.11],
    [49.24, 8.13],
]

const polygon2: LatLngExpression[] = [
    [49.18, 8.09],
    [49.15, 8.13],
    [49.10, 8.16],
    [49.07, 8.18],
    [49.03, 8.30],
    [48.98, 8.32],
    [48.96, 8.40],
    [48.99, 8.47],
    [49.05, 8.53],
    [49.11, 8.61],
    [49.19, 8.70],

    [49.30, 8.74],

    [49.42, 8.72],
    [49.43, 8.69],
    [49.47, 8.66],

    [49.54, 8.67],
    [49.68, 8.64],
    [49.73, 8.61],
    [49.74, 8.50],
    [49.73, 8.45],
    [49.69, 8.36],
    [49.66, 8.34],
    [49.68, 8.28],
    [49.71, 8.24],
    [49.75, 8.16],
    [49.76, 8.13],
    [49.75, 8.10],
    [49.74, 8.07],
    [49.72, 8.09],
    [49.69, 8.12],
    [49.67, 8.16],
    [49.64, 8.19],
    [49.62, 8.18],


    [49.52, 8.14],
    [49.51, 8.17],
    [49.50, 8.17],
    [49.49, 8.16],
    [49.46, 8.15],
    [49.45, 8.15],
    [49.44, 8.17],
    [49.44, 8.19],
    [49.38, 8.18],
    [49.37, 8.14],
    [49.36, 8.12],
    [49.35, 8.12],
    [49.32, 8.15],
    [49.28, 8.11],
    [49.24, 8.13],
]

const VacancyMap = ()  => {
    return (
        <>
            <MapContainer style={{height: "80vh"}} center={center} zoom={9} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon pathOptions={{ color: 'yellow' }} positions={polygon} />
                <Polygon pathOptions={{ color: 'green' }} positions={polygon2} />
            </MapContainer>
        </>
    )
}

export default VacancyMap