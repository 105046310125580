import React from "react";

type Icons8FluentIconProps = {
    name: string,
    size: number
}

type Icons8FluentIconVariantProps = {
    size: number
}

export const Icons8FluentIcon: React.FC<Icons8FluentIconProps> = ({name, size}) => {
    const image_url = `https://img.icons8.com/fluency/${size}/${name}.png`

    return <img height={size} src={image_url} alt={name}/>
}

export const I8fSave: React.FC<Icons8FluentIconVariantProps> = ({size}) => {
    return <Icons8FluentIcon name={"save"} size={size}/>
}

export const I8fMemorySlot: React.FC<Icons8FluentIconVariantProps> = ({size}) => {
    return <Icons8FluentIcon name={"memory-slot"} size={size}/>
}

export const I8fBroadcasting: React.FC<Icons8FluentIconVariantProps> = ({size}) => {
    return <Icons8FluentIcon name={"broadcasting"} size={size}/>
}

export const I8fProcessor: React.FC<Icons8FluentIconVariantProps> = ({size}) => {
    return <Icons8FluentIcon name={"processor"} size={size}/>
}