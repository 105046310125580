import React from "react";
import {Chip, Typography} from "@mui/joy";

interface DiskChipProps {
    diskClass: string;
    size: string;
}

export const DiskChip: React.FC<DiskChipProps> = ({diskClass, size}) => {

    let icon: string;

    switch (diskClass) {
        case "hdd":
            icon = "hdd"
            break;
        case "ssd":
            icon = "ssd";
            break;
        default:
            icon = "m.2-ssd"
            break;
    }

    const image_url = `https://s3.alexware.systems/icons/icons8/fluency/icons8-${icon}-48.png`

    return <Chip
        startDecorator={
            <img
                height={24}
                src={image_url}
            />
        }
    >
        <Typography style={{minWidth: "3em"}}>{size}</Typography>
    </Chip>
}