import * as React from 'react';
import {Grid, Typography} from "@mui/joy";
import {Gallery} from "react-grid-gallery";
import {thumbnail_style, tileViewport} from "./ImageComponent";

import "./gallery.css"

const images = [
    {src: "gallery/_MG_0067-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0070-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0236-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0289-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0334-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0365-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0366-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0392-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0493-min.jpg", width: 1800, height: 1198},
    {src: "gallery/_MG_0494-min.jpg", width: 1800, height: 1198},
]

export default function MasonGallery() {

    return (
        <Grid container justifyContent="center" sx={{marginTop: 5}}>
            <Grid xs={12} md={10} xl={8}>
                <Typography level="h1" id={"gallery"}>
                    Gallery
                </Typography>
                <Typography level="h4">
                    Vacation photos
                </Typography>
                <Gallery
                    images={images} enableImageSelection={false}
                    // @ts-ignore
                    //thumbnailStyle={thumbnail_style}
                />
            </Grid>
        </Grid>
    );
}