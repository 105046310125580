import * as React from "react";
import {Avatar, ListItem, ListItemDecorator} from "@mui/joy";

const RequirementItem = (props: {icon: string, primary: string | React.ReactElement, secondary?: string | React.ReactElement}) => {
    return (
        <ListItem>
            <ListItemDecorator>
                <Avatar sx={{ bgcolor: 'rgba(0,0,0,0)', width: 48, height: 48 }}>
                    <img height={48}
                         width={48}
                         src={"https://s3.alexware.systems/icons/icons8/fluency/icons8-" + props.icon + "-48.png"}
                     />
                </Avatar>
            </ListItemDecorator>
            <>
                {props.primary} {props.secondary ? <>- {props.secondary}</> : undefined}
            </>
        </ListItem>
    )
}

export default RequirementItem