import React from 'react';
import './App.css';
import {CssBaseline, CssVarsProvider, extendTheme} from "@mui/joy";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import IndexPage from "./pages";
import VacancyPage from "./pages/vacancy";

function App() {

    const queryClient = new QueryClient()

    const router = createBrowserRouter([
        {
            path: "/",
            element: <IndexPage/>
        },
        {
            path: "/vacancy",
            element: <VacancyPage/>
        }
    ])

    const theme = extendTheme({
        colorSchemes: {
            dark: {
                palette: {
                    background: {
                        body: "rgb(18, 18, 18)"
                    }
                }
            },
            light: {
                palette: {
                    background: {
                        body: "rgba(0,0,0,0.06)",
                        surface: "rgba(0,0,0,0.06)"
                    }
                }
            }
        }
    })


    return (
        <CssVarsProvider defaultMode="system" theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
          </QueryClientProvider>
        </CssVarsProvider>
    );

}

export default App;
