import React from "react";
import {useQuery} from "@tanstack/react-query";
import {LinearProgress, Table, Typography} from "@mui/joy";
import ReactFlow, {Background, MarkerType, Position} from "reactflow";
import 'reactflow/dist/style.css';

interface iSystemMetric {
    name: string,
    cpu: number,
    mem: number,
    mem_cap: number,
    power: number,
    datacenter: string
}

interface iMetricMeta {
    sum_power: number
}

interface iMetricResponse {
    meta: iMetricMeta,
    nodes: iSystemMetric[]
}

export const SystemMetrics: React.FC = () => {

    const {data, isSuccess} = useQuery({
        queryKey: ["metrics"],
        queryFn: async (): Promise<iMetricResponse> => {
            const response = await fetch("https://public.alexware.systems/metrics")
            return response.json()
        }
    })

    const nodeDefaults = {
        sourcePosition: Position.Right,
        targetPosition: Position.Left
    };

    const edgeDefaults = {
        animated: true,
        markerEnd: {type: MarkerType.ArrowClosed},
        style: {strokeWidth: 2}
    }

    const nodes = [
        { id: '1', position: { x: 0, y: 50 }, data: { label: 'Proxmox Metrics' }, ...nodeDefaults },
        { id: '2', position: { x: 200, y: 50 }, data: { label: 'InfluxDB' }, ...nodeDefaults },
        { id: '3', position: { x: 400, y: 100 }, data: { label: 'Public API' }, ...nodeDefaults },
        { id: '4', position: { x: 600, y: 100 }, data: { label: 'Homepage' }, ...nodeDefaults },
        { id: '5', position: { x: 400, y: 0 }, data: { label: 'Grafana' }, ...nodeDefaults },
    ]

    const edges: any[] = [
        {id: '1-2', source: '1', target: '2', ...edgeDefaults},
        {id: '2-3', source: '2', target: '3', ...edgeDefaults},
        {id: '2-5', source: '2', target: '5', ...edgeDefaults},
        {id: '3-4', source: '3', target: '4', ...edgeDefaults}
    ]

    if (isSuccess) {
        return <>
            <Typography level="h3">
                The data flow
            </Typography>
            <div style={{height: "270px"}}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    proOptions={{hideAttribution: true}}
                    preventScrolling={false}
                    fitView={true}
                    translateExtent={[[0, 0], [400, 200]]}
                >
                    <Background />
                </ReactFlow>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Node</th>
                        <th>CPU %</th>
                        <th>Memory %</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.nodes.map((node) =>
                        <tr>
                            <td>{node.name}</td>
                            <td>
                                <LinearProgress
                                    size="lg"
                                    determinate
                                    value={node.cpu}
                                    color={node.cpu > 80 ? "danger" : "primary"}
                                />
                            </td>
                            <td>
                                <LinearProgress
                                    size="lg"
                                    determinate
                                    value={(node.mem/node.mem_cap)*100}
                                    color={(node.mem/node.mem_cap)*100 > 85 ? "danger" : "primary"}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    } else {
        return <>loading...?</>
    }

}