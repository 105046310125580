import * as React from 'react';
import {Timeline} from 'rsuite';

import VitaeThingy from "./VitaeThingy";
import {Button, Grid, Stack, Typography, useTheme} from "@mui/joy";

import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/Timeline/styles/index.css';

import vitaeEntries from "../../data/vitae.json";
import {useMediaQuery} from "@mui/material";

export default function Vitae() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Grid container justifyContent="center" sx={{marginTop: 2}}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                // alignItems="center"
                xs={12} md={10} xl={8}
            >
                <Stack direction="row" justifyContent={"space-between"}>
                    <Typography level="h2" id={"timeline"}>
                        Timeline
                    </Typography>
                    <Button
                        startDecorator={
                            <img height={24}
                                 width={24}
                                 src={"https://s3.alexware.systems/icons/icons8/fluency/icons8-seiten%C3%BCbersicht-2-48.png"}/>
                        }
                        component={"a"}
                        download='AlexanderKehr_CV.pdf'
                        href={"https://s3.alexware.systems/awsys/documents/alexanderkehr/clw5a2muh066no3vhpxurerxu.pdf"}
                    >
                        View Curriculum Vitae
                    </Button>
                </Stack>

                <Timeline align={matches ? "alternate" : "left"} endless isItemActive={Timeline.ACTIVE_FIRST}>
                    {vitaeEntries.map((entry, index) =>
                        <Timeline.Item>
                            <VitaeThingy entry={entry}/>
                        </Timeline.Item>
                    )}
                </Timeline>
            </Grid>

        </Grid>
    );
}