import React from "react";
import {Card, Chip, Grid, Typography} from "@mui/joy";

export default function AdditionalSkills() {

    const skills = [
        {name: "3D Modelling", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-blender-3d-48.png"},
        {name: "Nextcloud", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-nextcloud-48.png"},
        {name: "Proxmox", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-proxmox-48.png"},
        {name: "Virtualization", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-virtualbox-48.png"},
        {name: "S3 Storage", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-cloud-speicherplatz-48.png"},
    ]

    const preferredSoftware = [
        {name: "Blender", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-blender-3d-48.png"},
        {name: "IntelliJ IDEA", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-jetbrains-toolbox-48.png"},
        {name: "Proxmox", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-proxmox-48.png"},
        {name: "Fedora Silverblue", icon: "https://s3.alexware.systems/icons/icons8/fluency/icons8-fedora-48.png"},
    ]

    return (
        <Grid container justifyContent="center" sx={{marginTop: 2}}>
            <Grid xs={12} md={10} xl={8}>
                <Card variant={"soft"}>
                    <Grid container spacing={2}
                          style={{
                              margin: '0.1rem',
                              marginBottom: '1rem',
                    }}
                    >
                        <Grid xs={12} md={6}>
                            <Typography level="h4" id={"additional_skills"}>
                                Additional Experience
                            </Typography>
                            {skills.map((skill, index) => {
                                return (
                                    <Chip
                                        variant={"outlined"}
                                        startDecorator={
                                            skill.icon ?
                                              <img
                                                  height={24}
                                                  width={24}
                                                  src={skill.icon}/>
                                              : undefined
                                        }
                                        style={{margin: '2px'}}
                                    >
                                        {skill.name}
                                    </Chip>
                                )
                            })}
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Typography level="h4">
                                Preferred Software
                            </Typography>
                            {preferredSoftware.map((skill, index) => {
                                return (
                                    <Chip
                                        variant={"outlined"}
                                          startDecorator={
                                              skill.icon ?
                                                  <img
                                                      height={24}
                                                      width={24}
                                                      src={skill.icon}/>
                                                  : undefined
                                          }
                                          style={{margin: '2px'}}
                                    >
                                        {skill.name}
                                    </Chip>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}