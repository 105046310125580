import React from "react";
import {Chip} from "@mui/joy";

interface NetworkClassChipProps {
    networkClass: string;
}

export const NetworkClassChip: React.FC<NetworkClassChipProps> = ({networkClass}) => {

    let icon: string;
    let label: string;

    switch (networkClass) {
        case "1gbit":
            icon = "rj45";
            label = "Gigabit";
            break;
        case "10gbit":
            icon = "netzwerkkarte";
            label = "SFP+";
            break;
        default:
            icon = "offline";
            label = "none";
            break;
    }

    const image_url = `https://s3.alexware.systems/icons/icons8/fluency/icons8-${icon}-48.png`

    return <Chip
        startDecorator={
            <img
                height={24}
                src={image_url}
            />
        }
    >
        {label}
    </Chip>
}