import React from "react";
import {Card, Grid, Link, Stack, Typography} from "@mui/joy";

export default function Footer() {
    return (
        <Grid container justifyContent="center" style={{marginTop: '5rem'}}>
            <Grid xs={12} md={10} xl={8}>
                <Card variant="soft">
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} md={5} xl={4}>
                            <Typography level="body-sm" gutterBottom>
                                <Stack direction="row" spacing={2}>
                                    <img height={24}
                                         width={24}
                                         src={"https://s3.alexware.systems/icons/icons8/fluency/icons8-nachricht-48.png"}/>
                                    <Link href="mailto:alexanderkehr@alexware.systems" underline="none">
                                        alexanderkehr@alexware.systems
                                    </Link>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <img height={24}
                                         width={24}
                                         src={"https://s3.alexware.systems/icons/icons8/fluency/icons8-telefon-48.png"}/>
                                    <Link href="tel:+491622626294" underline="none">
                                        +49 162 2626294
                                    </Link>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <img height={24}
                                         width={24}
                                         src={"https://s3.alexware.systems/icons/icons8/fluency/icons8-matrix-48.png"}/>
                                    <Link href="https://matrix.to/#/@alexanderkehr:alexware.systems" underline="none">
                                        @alexanderkehr:alexware.systems
                                    </Link>
                                </Stack>
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={5} xl={4}>
                            <Stack direction="row" spacing={2}>
                                <img height={24}
                                     width={24}
                                     src={"https://s3.alexware.systems/icons/icons8/fluency/icons8-icons8-neues-logo-48.png"}/>
                                <Typography level="body-sm" gutterBottom>
                                    Icons from <a href="https://icons8.de/">Icons8</a>
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )

}