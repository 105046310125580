import React from "react";
import {ListItemAvatar, ListItemText, useMediaQuery} from "@mui/material";
import {Avatar, Button, ButtonGroup, ListItem, useTheme} from "@mui/joy";

type ProjectPortfolioItemProps = {
    name: string,
    description: string,
    icon: string | React.ReactElement,
    links: {
        href: string,
        text: string,
        short_text: string | undefined,
        icon: string | React.ReactElement,
    }[]
}

export const ProjectPortfolioItem: React.FC<ProjectPortfolioItemProps> = ({name, description, icon, links}) => {
    const theme = useTheme();
    const is_big = useMediaQuery(theme.breakpoints.up('md'));

    return <ListItem>
        <ListItemAvatar>
            {React.isValidElement(icon) ?
                icon
                :
                <Avatar sx={{bgcolor: '#0000000F'}}>
                    <img
                        height={36}
                        src={icon}
                    />
                </Avatar>
            }
        </ListItemAvatar>
        <ListItemText
            primary={name}
            secondary={description}
        />
        <ButtonGroup>
            {links.map((link) =>
                <Button
                    startDecorator={
                        React.isValidElement(link.icon) ?
                            link.icon
                            :
                            <img
                                height={32}
                                src={link.icon}
                            />
                    }
                    component={"a"}
                    href={link.href}
                >
                    { is_big ? link.text : link.short_text }
                </Button>
            )}
        </ButtonGroup>
    </ListItem>
}